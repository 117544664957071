<script setup>
import {ref, computed} from "vue";
import {useComicStore} from "@/stores/comic";
import moment from "moment";

const props = defineProps({
  store: {
    type: Object,
    required: true,
  },
});

const comicStore = useComicStore();

const selectedIds = ref(
    comicStore.draftedParts
        .filter((part) => !part.release.releaseAt)
        .map((part) => part.pratilipiId)
);

const lastUpdated = (date) => moment(date).fromNow()
const releasedAt = (date) => moment(date).calendar()

const onDelete = async () => {
  await props.store.onDeleteClick(selectedIds.value);
  props.store.visible = false;
}
</script>

<template>
  <v-dialog persistent width="40rem" v-model="props.store.visible">
    <v-card rounded="lg" class="card">
      <div>
        <div class="flex-horizontal flex-row-spaced-between flex-row-center-vertical" >
          <div>
            <h3>Delete Drafted Episodes?</h3>
            <div class="ma-2"/>
            <p class="sub-text">Following drafted episodes will be deleted.</p>
          </div>
          <div class="flex-horizontal flex-row-end flex-gap-1">
            <v-btn @click="props.store.visible = false" class="font-weight-bold radius-rounded" text small rounded>
              Cancel
            </v-btn>
            <v-btn @click="onDelete" class="font-weight-bold radius-rounded text-dark" color="secondary" small>Delete
            </v-btn>
          </div>
        </div>
        <div class="ma-5"/>
        <v-divider/>
        <div class="ma-5"/>
        <div v-for="item in comicStore.draftedParts" :key="item.pratilipiId">
          <div class="flex-horizontal flex-row-center-vertical flex-gap-0-5">
            <v-checkbox v-model="selectedIds" :value="item.pratilipiId" color="error" />
            <img class="img-class" :src="item.coverImage"/>
            <div class="ma-0-5"/>
            <div class="meta">
              <span class="part-text">Part: {{ item.partNumber }}</span>
              <span>{{ item.title }}</span>
              <span class="updated-text">Updated: {{ lastUpdated(item.updatedAt) }}</span>
            </div>
            <div class="grow" />
            <small v-if="item.release.releaseAt" class="release-text"
            >Release scheduled for
              {{ releasedAt(item.release.releaseAt) }}</small
            >
          </div>
          <div class="ma-2"/>
        </div>
      </div>
    </v-card>
  </v-dialog>
</template>

<style scoped>
.card {
  color: var(--color-text);
  background-color: var(--color-divider);
  padding: 2rem;
}

.img-class {
  width: 5rem;
  height: 5rem;
  border-radius: 0.5rem;
}

.sub-text {
  font-size: 0.9rem;
  opacity: 0.5;
}

.meta {
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  gap: 0.3rem
}

.part-text {
  font-size: x-small
}

.updated-text {
  font-size: small;
  opacity: 0.7
}

.grow {
  flex-grow: 1
}

.release-text {
  font-size: small;
  opacity: 0.7;
  overflow: hidden;
  white-space: nowrap;
}
</style>
