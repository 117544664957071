import { defineStore } from "pinia/dist/pinia";
import { ref } from "vue";

const options = () => {
  const visible = ref(false);
  const alertData = ref({});

  const showAlert = ({
    title,
    message,
    type,
    positiveButtonText = "OK",
    positiveButtonVisible = true,
    negativeButtonText = "Cancel",
    negativeButtonVisible = true,
    persistent = false,
    positiveClick = () => {},
    negativeClick = () => {},
  }) => {
    alertData.value = {
      title,
      message,
      type,
      positiveButtonText,
      positiveButtonVisible,
      negativeButtonText,
      negativeButtonVisible,
      persistent,
      positiveClick,
      negativeClick,
    };
    visible.value = true;
  };

  return { visible, alertData, showAlert };
};

export const useMainAlertStore = defineStore("main-alert", options);
export const useUploadAlertStore = defineStore("upload-alert", options);

export const useUploadDialogStore = defineStore("upload", () => {
  const visible = ref(false);
  const uploadDialogData = ref({
    pratilipi: {
      title: "",
      titleEn: "",
      summary: "",
      partNumber: ""
    },
  });

  const show = ({
    pratilipi,
    onClose = () => {}
  }) => {
    uploadDialogData.value = {
      pratilipi,
      onClose
    };
    visible.value = true;
  };

  return { visible, uploadDialogData, show }
})


export const useScheduleDialogStore = defineStore("schedule", () => {
  const visible = ref(false);
  const scheduleDialogData = ref({
    pratilipi: {},
  });

  const show = ({
    pratilipi,
    onScheduleClick = (date) => {},
  }) => {
    scheduleDialogData.value = {
      pratilipi,
      onScheduleClick
    };
    visible.value = true;
  };

  return { visible, scheduleDialogData, show }
});


export const useDraftedDialogStore = defineStore("drafted-dialog", () => {
  const visible = ref(false);

  const draftedDialogData = ref({
    onDeleteClick: (selectedIds) => {},
  })

  const show = ({
    onDeleteClick = (selectedIds) => {},
  }) => {
    draftedDialogData.value = { onDeleteClick };
    visible.value = true;
  };

  return { visible }
});
