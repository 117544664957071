<script setup>
import { ref } from "vue";
import EpisodeItem from "@/components/motion-comics/EpisodeItem.vue";
import UploadDialog from "@/components/motion-comics/dialog/UploadDialog.vue";
import ScheduleDialog from "@/components/motion-comics/dialog/ScheduleDialog.vue";
import { useComicStore } from "@/stores/comic";
import { useDataStore } from "@/stores/data";
import { useMainAlertStore, useUploadDialogStore, useScheduleDialogStore } from "@/stores/alert";
import { useVideoStore } from "@/stores/videoUi";
import createViewModel from "@/pages/motion-comics/CreateViewModel";

const dataStore = useDataStore();
const comicStore = useComicStore();
const uiStore = useVideoStore();
const mainAlertStore = useMainAlertStore();
const uploadDialogStore = useUploadDialogStore();
const scheduleDialogStore = useScheduleDialogStore();

const onUploadButtonClick = (episode) => {
  uploadDialogStore.show({
    pratilipi: episode,
    onClose: () => { onUploadDialogClose(episode) },
  });
};

const onUploadDialogClose = (pratilipi) => {
  // update pratilipi of seriesPart
};

const onEpisodeDelete = (pratilipi) => {
  mainAlertStore.showAlert({
    title: "Delete Episode",
    type: "warning",
    message: `Are you sure you want to delete episode "${pratilipi.partNumber} • ${pratilipi.title}"?`,
    positiveButtonText: "OK",
    positiveClick: async () => {
      uiStore.enableLoadingFullScreen();
      createViewModel.removeEpisode(pratilipi.pratilipiId)
          .catch((e) => {
            mainAlertStore.showAlert({
              title: "Error",
              type: "error",
              message: `Failed to delete episode: ${e}`,
              positiveButtonText: "OK",
            });
          })
          .finally(() => {
            uiStore.stopLoading();
          });
    }
  });
}

const onAddNewPartClick = async () => {
  uiStore.enableLoadingFullScreen();
  await createViewModel.addEpisode().then((episode) => {
    return createViewModel.reload().then(() => {
      const pratilipi = createViewModel.getEpisode(episode.pratilipiId);
      uploadDialogStore.show({
        pratilipi: pratilipi,
        onClose: () => onUploadDialogClose(pratilipi)
      });
    })
  }).catch((e) => {
    mainAlertStore.showAlert({
      title: "Error",
      type: "warning",
      message: `Failed to add a new episode: ${e}`,
      negativeButtonVisible: false,
      positiveButtonText: "OK",
    });
  }).finally(() => {
    uiStore.stopLoading();
  });
};

const onScheduleDialogClick = async (part) => {
  scheduleDialogStore.show({
    pratilipi: part,
    onScheduleClick: (date) => {
      uiStore.enableLoadingFullScreen();
      createViewModel.scheduleRelease(part, date)
          .catch((e) => {
            mainAlertStore.showAlert({
              title: "Error",
              type: "error",
              message: `Failed to schedule episode: ${e}`,
              negativeButtonVisible: false,
              positiveButtonText: "OK",
            });
          }).finally(() => {
        uiStore.stopLoading();
      });
    }
  });
};
</script>

<template>
  <div>
    <div class="ma-5"></div>
    <div class="flex-horizontal flex-row-center-vertical flex-gap-0-5">
      <h3>Episodes</h3>
      <v-icon size="1.2rem">mdi-play-circle-outline</v-icon>
    </div>
    <div class="ma-2"></div>
    <div
      class="flex-horizontal .flex-row-center-vertical flex-row-spaced-between"
    >
      <v-chip-group
        mandatory
        @change="dataStore.selectedEpisodeState = $event"
        :value="dataStore.selectedEpisodeState"
      >
        <v-chip
          v-for="item in dataStore.episodeStates"
          :key="item"
          :value="item"
          small
          color="var(--color-surface)"
          active-class="selected"
        >
          <span>{{ item }}</span>
        </v-chip>
      </v-chip-group>
      <v-btn
        @click="onAddNewPartClick"
        class="font-weight-bold text-dark"
        color="info"
        >Add New Episode</v-btn
      >
    </div>
    <div class="ma-8"></div>
    <div v-for="item in comicStore.seriesParts" :key="item.pratilipiId">
      <EpisodeItem
        :data="item"
        @click:upload="onUploadButtonClick"
        @click:schedule="onScheduleDialogClick"
      />
      <div class="ma-5"></div>
      <v-divider></v-divider>
      <div class="ma-5"></div>
    </div>

    <div
      class="container-part flex-horizontal flex-row-center-vertical flex-row-center-horizontal"
      @click="onAddNewPartClick"
    >
      <div class="container-add-part"></div>
      <div class="flex-row-center-vertical flex-gap-1">
        <v-icon class="icon-plus" color="surface">mdi-plus</v-icon>
        <h4>Add New Part</h4>
      </div>
    </div>

    <UploadDialog :store="uploadDialogStore" @update:delete="onEpisodeDelete" />

    <ScheduleDialog :store="scheduleDialogStore" />
  </div>
</template>

<style scoped>
.icon-plus {
  background-color: var(--color-text);
  width: 35px;
  height: 35px;
  border-radius: 20rem;
}
.container-part {
  position: relative;
  opacity: 0.5;
  width: 100%;
  height: 80px;
  transition: opacity 0.2s ease-in-out;
}
.container-part:hover {
  opacity: 1;
  cursor: pointer;
}
.selected {
  background-color: var(--color-text) !important;
  color: var(--color-background) !important;
}
.container-add-part {
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 0.5rem;
  background-color: var(--color-text);
  -webkit-mask-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='%23333' stroke-width='4' stroke-dasharray='6%2c 14' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
  mask-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='%23333' stroke-width='4' stroke-dasharray='6%2c 14' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
}
</style>
