<script setup>
import {ref, watch, toRefs} from "vue";

const props = defineProps({
  value: {
    type: Date,
    required: true,
  },
});

const emit = defineEmits(["input"]);

const changeDateByType = (type /*"today" | "tomorrow" */) => {
  if (type == "today") {
    emit("input", new Date());
  } else {
    emit("input", new Date(new Date().getTime() + 24 * 60 * 60 * 1000));
  }
};

</script>

<template>
  <div>
    <div class="full-width flex-horizontal flex-gap-1">
      <v-btn
        color="var(--color-surface)"
        @click="changeDateByType('today')"
        class="btn-common"
        >Today</v-btn
      >
      <v-btn
        color="var(--color-surface)"
        @click="changeDateByType('tomorrow')"
        class="btn-common"
        >Tomorrow</v-btn
      >
    </div>
    <div class="ma-2"></div>
    <date-picker
      :value="props.value"
      @input="$emit('input', $event)"
      is-expanded
      :min-date="new Date()"
      :is-dark="$vuetify.theme.dark == true"
    />
  </div>
</template>

<style scoped>
.vc-container {
  background-color: var(--color-divider);
  border-color: var(--color-divider);
}
.btn-common {
  background-color: var(--color-surface);
}
</style>
