import {defineStore} from "pinia/dist/pinia";
import {ref} from "vue";

const options = () => {
  const visible = ref(false);
  const menuItems = ref([]);

  /**
   * eg: array of
   * ```
   * {
   *       id: "complete",
   *       color: "info",
   *       icon: "mdi-check-all",
   *       text: "Mark Complete",
   *       enable: () => true,
   * }
   * ```
   */
  const setMenuItems = (items) => {
    menuItems.value = items;
  }

  return { visible, menuItems, setMenuItems }
}

export const useMainMenuStore = defineStore("main-menu", options);
export const useUploadMenuStore = defineStore("upload-menu", options);
