const Language = {
  Hindi: "Hindi",
  English: "English",
  Marathi: "Marathi",
  Tamil: "Tamil",
  Bengali: "Bengali",
  Gujarati: "Gujarati",
};

export default Language;
