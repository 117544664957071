import {ref, computed} from "vue";
import {defineStore} from "pinia";
import Language from "../data/model/lang";
import * as _ from "lodash";

export const useComicStore = defineStore("comic", () => {
  const _seriesData = ref(null);
  const seriesData = computed(() => _seriesData.value);

  const title = ref("Untitled Motion Comic");
  const titleEn = ref("");
  const summary = ref("");

  const state = ref("");

  const imageUrl = computed(() => {
    const url = _seriesData.value ? _seriesData.value.coverImage : "";
    return url.includes("?seriesId") ? url : "";
  })

  const language = ref(Language.Hindi);
  const genres = ref([]);

  const completedSeries = computed(() => {
    const state = _seriesData.value ? _seriesData.value.state : "";
    return state == "PUBLISHED"
  });

  const _seriesParts = ref(null);
  const seriesParts = computed(() => _seriesParts.value);

  const draftedParts = computed(() => {
    const parts = seriesParts.value ? seriesParts.value : [];
    return parts.filter((part) => part.state == "DRAFTED")
  });

  const publishedParts = computed(() => {
    const parts = seriesParts.value ? seriesParts.value : [];
    return parts.filter((part) => part.state == "PUBLISHED")
  });

  const isSeriesMetadataChanged = computed(() => {
    if (_.isNil(_seriesData.value)) return false;

    const lSummary = summary.value ? summary.value : "";
    const lTitleEn = titleEn.value ? titleEn.value : "";

    return title.value != _seriesData.value.title ||
      lTitleEn != (_seriesData.value.titleEn || "")  ||
      lSummary != (_seriesData.value.summary || "") ||
      language.value.toLowerCase() != _seriesData.value.language.toLowerCase() ||
      !_.isEqual(genres.value, _seriesData.value.category.system.map((genre) => genre.name))
  })

  const isMetadataChanged = computed(() => isSeriesMetadataChanged.value)

  const canPublishSeries = computed(() => ["DRAFTED", "INPROGRESS"].includes(state.value));

  const setSeriesData = (data) => {
    _seriesData.value = data;
  }

  const setSeriesParts = (data) => {
    _seriesParts.value = data;
  }

  const updateGenres = (newGenres) => {
    genres.value = newGenres;
  };

  const removeGenre = (genre) => {
    genres.value = genres.value.filter((g) => g !== genre);
  };

  return {
    title,
    titleEn,
    summary,
    language,
    state,
    imageUrl,
    genres,
    completedSeries,
    isMetadataChanged,
    isSeriesMetadataChanged,
    canPublishSeries,
    seriesData,
    seriesParts,
    draftedParts,
    publishedParts,
    updateGenres,
    removeGenre,
    setSeriesData,
    setSeriesParts
  };
});
