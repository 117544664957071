<script setup>
import "@/assets/base.css";
import "@/assets/utility.css";

import { computed } from "vue";
import CommonDialog from "@/components/motion-comics/dialog/CommonDialog.vue";
import { useComicStore } from "@/stores/comic";
import { useDataStore } from "@/stores/data";
const props = defineProps({
  visible: {
    type: Boolean,
    required: true,
  },
});

const comicStore = useComicStore();
const dataStore = useDataStore();

const isGenrePresent = computed((genre) => {
  return comicStore.genres.includes(genre);
});
</script>

<template>
  <CommonDialog
    title="Select Genres"
    message="Add multiple category"
    :visible="props.visible"
    @update:visible="$emit('update:visible', $event)"
  >
    <template #content>
      <div class="spacer-2" />
      <div class="genre">
        <v-chip-group
          @change="comicStore.updateGenres"
          :value="comicStore.genres"
          class="chip-group"
          multiple
          column
          filter
        >
          <v-chip
            :key="item"
            :value="item"
            v-for="item of dataStore.genres"
            filter
            :outlined="!comicStore.genres.includes(item)"
          >
            <p>{{ item }}</p>
          </v-chip>
        </v-chip-group>
      </div>
    </template>
  </CommonDialog>
</template>
<style scoped>
.chip-group {
  display: flex;
  gap: 0.1rem;
  max-width: 300px;
}
.spacer-2 {
  margin-top: 0.5rem;
}
.genre {
  display: flex;
  justify-content: center;
  align-items: center;
}
p {
  font-size: small;
  font-weight: bold;
}
</style>
