import { ref, computed } from "vue";
import { defineStore } from "pinia";

export const useDataStore = defineStore("data", () => {

  const staticEpisodeStates = ["Drafted", "Published", "All"];

  const _genres = ref([]);
  const genreMetadata = computed(() => _genres.value);
  const genres = computed(() => _genres.value.map(it => it.name));

  const episodeStates = ref([...staticEpisodeStates]);

  const selectedEpisodeState = ref(staticEpisodeStates[2]);

  const updateGenres = (genreStrings) => {
    _genres.value = genreStrings;
  }

  const getRelativeEpisodeStates = computed(() => {
    let state;
    switch (selectedEpisodeState.value) {
      case "Published":
        state = 'PUBLISHED';
        break;
      case "Drafted":
        state = 'DRAFTED';
        break;
      default:
        state = 'PUBLISHED,DRAFTED';
    }
    return state;
  })

  return { genres, genreMetadata, episodeStates, selectedEpisodeState, getRelativeEpisodeStates, updateGenres };
});
