<script setup>
import "@/assets/base.css";
import "@/assets/utility.css";
import LanguageDialog from "@/components/motion-comics/dialog/LanguageDialog.vue";
import ImageUploader from "@/components/motion-comics/ImageUploader.vue";
import GenreDiaog from "@/components/motion-comics/dialog/GenreDialog.vue";
import AlertDialog from "@/components/motion-comics/dialog/AlertDialog.vue";
import DeleteDraftedDialog from "@/components/motion-comics/dialog/DeleteDraftedDialog.vue";
import Toolbar from "@/components/motion-comics/Toolbar.vue";
import EpisodeGroup from "@/components/motion-comics/EpisodeGroup.vue";
import InputItem from "@/components/motion-comics/InputItem.vue";
import Menu from "@/components/motion-comics/Menu.vue";
import { useComicStore } from "@/stores/comic";
import { useVideoStore } from "@/stores/videoUi";
import { useDataStore } from "@/stores/data";
import { useMainAlertStore, useDraftedDialogStore } from "@/stores/alert";
import { useMainMenuStore } from "@/stores/menu";
import router from "@/routes";
import { ref, onBeforeMount, watch, computed } from "vue";
import createViewModel from "./CreateViewModel";
import * as _ from "lodash";

const seriesId = router.currentRoute.params.comicId;

const comicStore = useComicStore();
const dataStore = useDataStore();
const uiStore = useVideoStore();
const mainAlertStore = useMainAlertStore();
const mainMenuStore = useMainMenuStore();
const draftedDialogStore = useDraftedDialogStore();

const isLangDialogVisible = ref(false);
const isGenreDialogVisible = ref(false);

const setMenuItems = () => {
  mainMenuStore.setMenuItems([
    {
      id: "complete",
      color: "info",
      icon: "mdi-check-all",
      text: "Mark Complete",
      enable: () => !comicStore.completedSeries && !_.isEmpty(comicStore.publishedParts),
    },
    {
      id: "delete",
      color: "error",
      icon: "mdi-delete-outline",
      text: "Delete",
      enable: () => true,
    },
  ]);
};

const onSave = () => {
  return onPublishSeriesMetadata()
};

const onDelete = () => {
  mainAlertStore.showAlert({
    title: "Delete",
    type: "warning",
    message: "Are you sure you want to delete this series?",
    negativeButtonVisible: true,
    positiveButtonText: "Delete",
    positiveClick: async () => {
      uiStore.enableLoadingFullScreen();
      await createViewModel.deleteComic();
      uiStore.stopLoading();
      await router.replace("/me/author");
    },
  });
};

const onMarkComplete = () => {
  const endgame = async () => {
    createViewModel.markSeriesAsCompleted()
        .catch((e) => {
          mainAlertStore.showAlert({
            title: "Error",
            type: "error",
            message: `Failed to mark series as completed: ${e}`,
            positiveButtonText: "OK",
          });
        })
        .finally(() => { uiStore.stopLoading()})
  };

  mainAlertStore.showAlert({
    title: "Mark Complete",
    type: "info",
    message: "Are you sure you want to mark this series as complete?",
    negativeButtonVisible: true,
    positiveButtonText: "Mark Complete",
    positiveClick: async () => {
      uiStore.enableLoadingFullScreen();
      const draftedEpisodes = comicStore.draftedEpisodes;
      if (!_.isEmpty(draftedEpisodes)) {
        draftedDialogStore.show({
          onDeleteClick: async (pratilipiIds) => {
            await createViewModel.removeEpisodes(pratilipiIds)
                .then(() => endgame())
          }
        })
      } else {
        await endgame()
      }
    },
  });
}

const onPublish = () => {
  const promise = comicStore.state == "DRAFTED" ? createViewModel.publishSeries() : createViewModel.unPublishSeries();
  mainAlertStore.showAlert({
    title: publishButtonText.value,
    type: "info",
    message: `Are you sure you want to ${_.lowerCase(publishButtonText.value)} this series?`,
    negativeButtonVisible: true,
    positiveButtonText: publishButtonText.value,
    positiveClick: async () => {
      uiStore.enableLoadingFullScreen();
      promise.then(() => {
        mainAlertStore.showAlert({
          title: "Success",
          type: "info",
          message: "Series published successfully",
          positiveButtonText: "OK",
        })
      }).catch((e) => {
        mainAlertStore.showAlert({
          title: e.title || "Error",
          type: "warning",
          message: e.message || `Failed to upload series cover image (${e})`,
          negativeButtonVisible: false,
          positiveButtonText: "OK",
        });
      }).finally(() => { uiStore.stopLoading(); });
    },
  });
};

const onAutoGenerateEnglishTitleClicked = async () => {
  uiStore.enableLoadingFullScreen();
  await createViewModel.autoGenerateEnglishTitle().catch(() => {
    mainAlertStore.showAlert({
      title: "Error",
      type: "warning",
      message: "Failed to auto generate English title.",
      negativeButtonVisible: false,
      positiveButtonText: "OK",
    });
  });
  uiStore.stopLoading();
};

const onPublishSeriesMetadata = async () => {
  uiStore.enableLoadingFullScreen();
  await createViewModel.publishSeriesMetadata().catch((e) => {
    mainAlertStore.showAlert({
      title: "Error",
      type: "warning",
      message: `Failed to save series metadata (${e})`,
      negativeButtonVisible: false,
      positiveButtonText: "OK",
    });
  });
  uiStore.stopLoading();
};

const onResetSeriesMetadata = async () => {
  uiStore.enableLoadingFullScreen();
  await createViewModel.fetchSeries().catch();
  uiStore.stopLoading();
};

const onMenuItemClick = async (id) => {
  switch (id) {
    case "delete":
      await onDelete();
      break;
    case "complete":
      await onMarkComplete();
      break;
  }
};

const onLanguageChange = async (language) => {
  uiStore.enableLoadingFullScreen();
  await createViewModel.fetchAvailableGenres(language);
  comicStore.updateGenres([]); // empty
  uiStore.stopLoading();
};

const onUploadSeriesCover = async (file) => {
  uiStore.enableLoadingFullScreen();
  await createViewModel.updateSeriesCoverImage(file).catch((e) => {
    mainAlertStore.showAlert({
      title: e.title || "Error",
      type: "warning",
      message: e.message || `Failed to upload series cover image (${e})`,
      negativeButtonVisible: false,
      positiveButtonText: "OK",
    });
  });
  uiStore.stopLoading();
}

watch(
  () => dataStore.getRelativeEpisodeStates,
  async (state) => {
    uiStore.enableLoadingFullScreen();
    await createViewModel.fetchSeriesParts(state).catch((e) => {
      mainAlertStore.showAlert({
        title: "Error",
        type: "warning",
        message: `Failed to fetch series parts (${e})`,
        negativeButtonVisible: false,
        positiveButtonText: "OK",
      });
    });
    uiStore.stopLoading();
  }
);

const publishButtonText = computed(() => comicStore.state == "DRAFTED" ? "Publish" : "Unpublish")

// All processing code
uiStore.enableLoadingFullScreen();

onBeforeMount(async () => {
  setMenuItems();
  await createViewModel
    .init(seriesId)
    .catch((e) => {
      console.error(e);
      mainAlertStore.showAlert({
        title: "Invalid",
        type: "warning",
        message:
          "Could not fetch series data, this maybe due to wrong series Id.",
        persistent: true,
        negativeButtonVisible: false,
        positiveButtonText: "Go to Dashboard",
        positiveClick: () => {
          router.replace("/me/author");
        },
      });
    })
    .finally(() => uiStore.stopLoading());
});
</script>

<template>
  <v-app>
    <v-main class="main-class">
      <div>
        <div
          v-show="uiStore.isFullScreenOverlay"
          class="progress-full-overlay"
        ></div>
        <Toolbar>
          <template #title>
            <input v-model="comicStore.title" type="text" />
          </template>
          <template #actions>
            <v-btn class="ssg" color="surface" :disabled="!comicStore.isMetadataChanged" @click="onSave">Save</v-btn>
            <v-btn :disabled="!comicStore.canPublishSeries" @click="onPublish" color="secondary">
              {{ publishButtonText }}
            </v-btn>
            <Menu :store="mainMenuStore" @click:item="onMenuItemClick" />
          </template>
        </Toolbar>
        <div class="spacer"></div>

        <div class="container">
          <h3>Video Comic Detail</h3>

          <div class="spacer"></div>

          <div class="detail">
            <div  class="uploader"><ImageUploader :image-url="comicStore.imageUrl" @update:file="onUploadSeriesCover" /></div>
            <div class="detail-inputs">
              <!-- Title -->
              <InputItem class="input-title" header="Comic Title">
                <template #input>
                  <input v-model="comicStore.title" type="text" />
                </template>
              </InputItem>
              <!-- Title (en) -->
              <InputItem class="input-title_en" header="Comic Title (English)">
                <template #input>
                  <div class="input-common-container">
                    <input v-model="comicStore.titleEn" type="text" />
                    <v-btn
                      @click="onAutoGenerateEnglishTitleClicked"
                      icon
                      small
                      rounded
                      class="edit-btn btn"
                    >
                      <v-icon rounded x-small>mdi-auto-fix</v-icon></v-btn
                    >
                  </div>
                </template>
              </InputItem>
              <!-- Language -->
              <InputItem
                :default-padding="false"
                class="input-language"
                header="Language"
              >
                <template #input>
                  <div class="input-common-container">
                    <input v-model="comicStore.language" readonly type="text" />
                    <v-btn
                      @click="isLangDialogVisible = true"
                      icon
                      fab
                      small
                      rounded
                      class="edit-btn btn"
                    >
                      <v-icon rounded x-small>mdi-pencil</v-icon></v-btn
                    >
                  </div>
                </template>
              </InputItem>
              <!-- Genre -->
              <InputItem
                :defaultPadding="false"
                class="input-genre"
                header="Genre"
              >
                <template #input>
                  <div class="input-common-container">
                    <v-chip-group
                      @change="comicStore.removeGenre"
                      class="chip-group-style"
                      column
                    >
                      <v-chip
                        :key="genre"
                        :value="genre"
                        v-for="genre in comicStore.genres"
                        outlined
                        small
                        close-icon="mdi-close"
                        close
                        color="info"
                        ><span class="text-bold">{{ genre }}</span></v-chip
                      >
                    </v-chip-group>
                    <v-btn
                      @click="isGenreDialogVisible = true"
                      icon
                      fab
                      small
                      rounded
                      class="edit-btn btn"
                    >
                      <v-icon x-small>mdi-pencil</v-icon></v-btn
                    >
                  </div>
                </template>
              </InputItem>
              <!-- Summary -->
              <InputItem class="input-summary" header="Summary">
                <template #input>
                  <textarea
                    v-model="comicStore.summary"
                    variant="flat"
                    no-resize="true"
                    class="summary-input"
                  />
                </template>
              </InputItem>
            </div>
          </div>

          <div class="ma-5"></div>

          <div class="btn-container">
            <v-btn
              @click="onResetSeriesMetadata"
              small
              class="font-weight-bold btn"
              rounded
              :disabled="!comicStore.isSeriesMetadataChanged"
              text
            >
              Reset
            </v-btn>
            <v-btn
              small
              class="font-weight-bold btn text-dark"
              rounded
              color="info"
              :disabled="!comicStore.isSeriesMetadataChanged"
              @click="onPublishSeriesMetadata"
            >
              Save
            </v-btn>
          </div>

          <div class="ma-5" />

          <v-divider />

          <EpisodeGroup />
        </div>

        <LanguageDialog
          :visible="isLangDialogVisible"
          @update:visible="isLangDialogVisible = $event"
          @update:language="onLanguageChange"
        />

        <GenreDiaog
          :visible="isGenreDialogVisible"
          @update:visible="isGenreDialogVisible = $event"
        />

        <AlertDialog :store="mainAlertStore" />

        <DeleteDraftedDialog :store="draftedDialogStore" />

        <div class="ma-10d" />
      </div>
    </v-main>
  </v-app>
</template>

<style scoped>
#app {
  text-align: start;
  color: var(--color-text);
}
.progress-full-overlay {
  height: 100vh;
  width: 100vw;
  position: fixed;
  z-index: 10;
  background-color: rgba(var(--color-accent-rgb), 0.2);
}
.btn {
  border-radius: 2rem;
}
.main-class {
  background-color: var(--color-background);
}
.detail {
  display: flex;
  gap: 1rem;
  flex-direction: column;
}
.detail-inputs {
  display: flex;
  gap: 1rem;
  flex-direction: column;
  width: 100%;
}
.summary-input {
  font-size: small;
  height: 10ch;
  overflow-y: auto;
  resize: none;
}
.chip-group-style {
  width: 80%;
  display: flex;
  flex-wrap: wrap;
}
.input-common-container {
  display: flex;
  justify-content: space-between;
}
.edit-btn {
  transform: translateX(10px);
}
.btn-container {
  display: flex;
  justify-content: flex-end;
  gap: 1rem;
  margin-inline: 1rem;
}
.uploader {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
@media (min-width: 720px) {
  .uploader {
    width: auto;
    display: block;
  }
  .detail {
    display: flex;
    flex-direction: row;
    column-gap: 1.5rem;
  }
}
@media (min-width: 1024px) {
  .detail-inputs {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    width: 100%;
    gap: 1rem;
  }
  .input-title {
    grid-column: span 3;
  }
  .input-title_en {
    grid-column: span 3;
  }
  .input-language {
    grid-column: span 1;
    grid-row: 3;
  }
  .input-genre {
    grid-column: span 2;
  }
  .input-summary {
    grid-column: span 3;
  }
}
</style>
