<script setup>
import "@/assets/base.css";
import "@/assets/utility.css";
import { computed } from "vue";
import CommonDialog from "@/components/motion-comics/dialog/CommonDialog.vue";
const props = defineProps({
  store: {
    type: Object,
    required: true
  }
});

const data = computed(() => props.store.alertData)

const mdiIcon = computed(() => {
  switch (props.store.alertData.type) {
    case "warning":
      return "mdi-alert-outline";
    default:
      return "mdi-information-outline";
  }
});

const mdiIconColor = computed(() => {
  switch (props.store.alertData.type) {
    case "warning":
      return "red";
    default:
      return "info";
  }
});

const onPositiveClick = async () => {
  await props.store.alertData.positiveClick();
  props.store.visible = false;
};
const onNegativeClick = async () => {
  await props.store.alertData.negativeClick();
  props.store.visible = false;
};
</script>

<template>
  <CommonDialog
    :title="data.title ?? ''"
    :visible="props.store.visible"
    :closeVisible="false"
    :appendIcon="mdiIcon"
    :appendIconColor="mdiIconColor"
    :persistent="data.persistent ?? false"
    message=""
    width="30rem"
    @update:visible="props.store.visible = $event"
  >
    <template #content>
      <div class="spacer" />
      <p>{{ data.message }}</p>
      <div class="spacer-2" />
      <div class="buttons">
        <v-btn
          v-if="data.negativeButtonVisible"
          @click="onNegativeClick"
          class="font-weight-bold radius-rounded"
          text
          >{{ data.negativeButtonText }}</v-btn
        >
        <v-btn
          v-if="data.positiveButtonVisible"
          @click="onPositiveClick"
          class="font-weight-bold radius-rounded"
          color="secondary"
          >{{ data.positiveButtonText }}</v-btn
        >
      </div>
    </template>
  </CommonDialog>
</template>

<style scoped>
.spacer-2 {
  height: 2rem;
}
.buttons {
  display: flex;
  justify-content: flex-end;
  gap: 0.5rem;
}
p {
  font-size: 0.9rem;
  color: var(--color-text-secondary);
}
</style>
