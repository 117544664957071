<script setup>
import {ref, computed} from "vue";
import UploadIcon from "../icons/upload.vue";
import * as _ from "lodash";

const props = defineProps({
  imageUrl: {
    type: String,
    required: true,
  },
  containerWidth: {
    type: String,
    required: false,
    default: "260px",
  },
  iconSize: {
    type: String,
    required: false,
    default: "48px",
  },
  circleDiameter: {
    type: String,
    required: false,
    default: "5rem",
  },
  cardBackgroundColor: {
    type: String,
    required: false,
    default: "var(--color-divider)",
  },
});

const isImgVisible = computed(() => !_.isEmpty(props.imageUrl));

const styles = computed(() => {
  return {
    vars: {
      "--container-width": `${props.containerWidth}`,
      "--icon-size": `${props.iconSize}`,
      "--circle-diameter": `${props.circleDiameter}`,
      "--card-background-color": `${props.cardBackgroundColor}`,
    },
  };
});

const emit = defineEmits(["update:file"]);

const onImagePickerSelected = () => {
  let input = document.createElement("input");
  input.type = "file";
  input.accept = "image/png, image/jpeg";
  input.onchange = (event) => {
    const file = event.target.files[0];
    emit("update:file", file);
  };
  input.click();
};

</script>

<template>
  <div :style="styles.vars">
    <div @click="onImagePickerSelected">
      <v-card  class="card" ripple>
        <img v-if="isImgVisible" class="img-class" :src="props.imageUrl"/>
        <UploadIcon class="icon"/>
      </v-card>
    </div>
    <p class="parent-p">
      *Image should have max size of 1.5MB and max resolution of
      500px(W)*500px(H)
    </p>
  </div>
</template>

<style scoped>
div {
  width: var(--container-width);
}

p {
  opacity: 0.8;
  text-align: center;
  margin-inline: 1.2rem;
  margin-top: 0.5rem;
  font-size: 0.7rem;
  color: var(--color-text-secondary);
}

.icon {
  width: var(--icon-size);
  z-index: 3;
  color: var(--vt-c-grey-dark);
}

.card {
  cursor: pointer;
  height: var(--container-width);
  border-radius: 0.7rem;
  background-color: var(--card-background-color);
  display: flex;
  justify-content: center;
  border: 1px solid var(--color-border);
  padding-block: 30px;
}

.img-class {
  position: absolute;
  top: 0;
  border-radius: 0.7rem;
  opacity: 1;
  width: var(--container-width);
  height: var(--container-width);
  transition: opacity 0.2s ease-in-out;
  z-index: 4;
}

.img-class:hover {
  opacity: 0.2;
  z-index: 5
}

.card::before {
  position: absolute;
  content: " ";
  display: block;
  top: calc((var(--container-width) / 2) - var(--circle-diameter) / 2);
  background: var(--color-background);
  border-radius: 10rem;
  z-index: 2;
  width: var(--circle-diameter);
  height: var(--circle-diameter);
}
</style>
