<script setup>
import {onMounted} from "vue";

const props = defineProps({
  store: {
    type: Object,
    required: true,
  },
  menuIcon: {
    type: String,
    required: false,
    default: "mdi-dots-vertical",
  },
  menuIconSize: {
    type: String,
    required: false,
    default: "default",
  },
  itemIconSize: {
    type: String,
    required: false,
    default: "default",
  }
});
const emit = defineEmits(["click:item"]);

onMounted(() => {
  // Close menu upon scroll
  window.addEventListener("scroll", () => {
    props.store.visible = false;
  });
});
</script>

<template>
  <v-menu nudge-bottom="5" v-model="props.store.visible" offset-y>
    <template v-slot:activator="{ on, attrs }">
      <v-btn icon v-bind="attrs" v-on="on"
        ><v-icon :size="props.menuIconSize">{{props.menuIcon}}</v-icon>
      </v-btn>
    </template>
    <div class="menu-content">
      <div v-for="(item, index) in props.store.menuItems" :key="index">
        <div
          v-ripple="item.enable()"
          :class="item.enable() ? '' : 'disable'"
          class="menu-item"
        >
          <div
            class="flex-horizontal flex-gap-1"
            @click="item.enable() ? $emit('click:item', item.id) : null"
          >
            <v-icon :size="itemIconSize" :color="item.color">{{ item.icon }}</v-icon>
            <p>{{ item.text }}</p>
          </div>
        </div>
        <v-divider v-if="index < props.store.menuItems.length - 1" />
      </div>
    </div>
  </v-menu>
</template>

<style scoped>
.disable {
  opacity: 0.2;
  background-color: var(--color-background);
}
.menu-item {
  padding: 0.5rem 1rem;
}
.menu-content {
  background-color: var(--color-surface);
}
</style>
