<script setup>
import TimeSelect from "@/pages/motion-comics/schedule/TimeSelectView.vue";
import DateSelect from "@/pages/motion-comics/schedule/DateSelectView.vue";
import moment from "moment";
import { computed, onMounted, ref, Transition, watch } from "vue";
import { omit } from "lodash";

const props = defineProps({
  store: {
    type: Object,
    required: true,
  }
});

const Screen = {
  Date: "Date",
  Time: "Time",
};

const screen = ref(Screen.Date);

const isSubmit = computed(() => screen.value == Screen.Time);

const currentDate = ref(new Date());
const formattedDate = computed(() => moment(currentDate.value).format("LL"));

const nextOrSubmit = () => {
  if (screen.value == Screen.Date) {
    screen.value = Screen.Time;
  } else {
    props.store.scheduleDialogData.onScheduleClick(currentDate.value);
    props.store.visible = false;
  }
};

watch(
  () => props.store.visible,
  (value) => {
    currentDate.value = new Date();
    screen.value = Screen.Date;
  }
);
</script>

<template>
  <v-dialog
    persistent
    @keydown.esc="props.store.visible = false"
    width="30rem"
    class="dialog"
    v-model="props.store.visible"
  >
    <v-card rounded="lg" class="card">
      <div class="container">
        <p class="schedule-title">Schedule Release Date</p>
        <div class="ma-1" />
        <h3>{{ formattedDate }}</h3>
        <div class="ma-1" />
        <v-divider width="100%"></v-divider>
        <div class="ma-2"></div>
        <div class="full-width">
          <v-slide-x-transition name="fade" mode="out-in">
            <DateSelect
              v-if="screen == Screen.Date"
              key="date-select"
              v-model="currentDate"
            />
            <TimeSelect
              v-else-if="screen == Screen.Time"
              key="time-select"
              v-model="currentDate"
            />
          </v-slide-x-transition>
        </div>
        <div class="ma-2"></div>
        <div class="full-width flex-horizontal flex-row-end flex-gap-1">
          <v-btn
            @click="props.store.visible = false"
            class="font-weight-bold radius-rounded"
            small
            text
          >
            Cancel</v-btn
          >
          <v-btn
            @click="nextOrSubmit"
            class="font-weight-bold radius-rounded"
            small
            color="secondary"
            >{{ isSubmit ? "Done" : "Next" }}</v-btn
          >
        </div>
      </div>
    </v-card>
  </v-dialog>
</template>

<style scoped>
.container {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.card {
  padding: 2rem;
  background-color: var(--color-divider);
  color: var(--color-text);
}
.schedule-title {
  font-size: 0.7rem;
  opacity: 0.8;
}
</style>
