<script setup>
import { computed } from "vue";
import * as _ from "lodash";

const props = defineProps({
  title: {
    type: String,
    required: true,
  },
  message: {
    type: String,
    required: true,
  },
  visible: {
    type: Boolean,
    required: true,
  },
  closeVisible: {
    type: Boolean,
    required: false,
    default: true,
  },
  appendIcon: {
    type: String,
    required: false,
    default: "",
  },
  appendIconColor: {
    type: String,
    required: false,
    default: "var(--color-text)",
  },
  persistent: {
    type: Boolean,
    required: false,
    default: false,
  },
  width: {
    type: String,
    required: false,
    default: "unset",
  },
});

const isAppendIconVisible = computed(() => {
  return !_.isEmpty(props.appendIcon);
});

const emit = defineEmits(["update:visible"]);

const onClickOutside = () => {
  if (props.persistent) return;
  emit('update:visible', false);
}
</script>

<template>
  <v-dialog
    :persistent="props.persistent"
    @click:outside="onClickOutside"
    @keydown.esc="$emit('update:visible', false)"
    :width="props.width"
    v-model="props.visible"
  >
    <v-card rounded="lg" class="card">
      <div>
        <div class="div-top">
          <div>
            <div class="flex-horizontal flex-gap-1 flex-row-center-vertical">
              <v-icon
                large
                :color="appendIconColor"
                v-if="isAppendIconVisible"
                >{{ props.appendIcon }}</v-icon
              >
              <h3>{{ props.title }}</h3>
            </div>
            <div class="spacer" />
            <p class="subtitle">{{ props.message }}</p>
          </div>
          <v-btn
            v-if="props.closeVisible"
            rounded
            @click="$emit('update:visible', false)"
            class="btn-close"
            icon
            ><v-icon>mdi-close</v-icon></v-btn
          >
        </div>
        <slot name="content" />
      </div>
    </v-card>
  </v-dialog>
</template>

<style scoped>
.div-top {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}
.btn-close {
  transform: translate(10px, -5px);
}
.spacer {
  margin-top: 0.5rem;
}
.card {
  background-color: var(--color-divider);
  padding: 2rem;
}
.subtitle {
  font-size: small;
}
</style>
