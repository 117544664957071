<script setup>
import { computed } from "vue";

const props = defineProps({
  min: {
    type: Number,
    required: false,
    default: 1,
  },
  max: {
    type: Number,
    required: false,
    default: 12,
  },
  value: {
    type: Number,
    required: true,
  },
});

const emit = defineEmits(["update:value"]);

const decrement = () => {
  emit("update:value", Math.max(props.value - 1, props.min));
};
const increment = () => {
  emit("update:value", Math.min(props.value + 1, props.max));
};

let isStarted = false;

function setTimeoutAndPerformAction(action) {
  setTimeout(() => {
    if (isStarted) {
      action();
      setTimeoutAndPerformAction(action);
    }
  }, 150);
}

const longPressAction = (
  event /*"up" | "down"*/,
  actionType /* "increment" | "decrement" */
) => {
  if (event == "down") {
    isStarted = true;
    setTimeoutAndPerformAction(() => {
      if (actionType == "increment") {
        increment();
      } else if (actionType == "decrement") {
        decrement();
      }
    });
  }
  if (event == "up") {
    isStarted = false;
  }
};

const number = computed(() => ("0" + props.value).slice(-2));
const isUpEnabled = computed(() => props.value > props.min);
</script>

<template>
  <div class="container">
    <v-btn
      @mousedown="longPressAction('down', 'decrement')"
      @mouseup="longPressAction('up', 'decrement')"
      @mouseleave="longPressAction('up', 'decrement')"
      @click="decrement"
      :disabled="!isUpEnabled"
      icon
      fab
      small
      class="radius-circle"
      ><v-icon class="icon">mdi-chevron-up</v-icon></v-btn
    >
    <h1>{{ number }}</h1>
    <v-btn
      @mousedown="longPressAction('down', 'increment')"
      @mouseup="longPressAction('up', 'increment')"
      @mouseleave="longPressAction('up', 'increment')"
      @click="increment"
      icon
      small
      fab
      class="radius-circle"
      ><v-icon class="icon">mdi-chevron-down</v-icon></v-btn
    >
  </div>
</template>

<style scoped>
.container {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-items: center;
}
.icon {
  color: var(--color-text-secondary) !important;
}
</style>
