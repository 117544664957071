<script setup>
import Language from "@/data/model/lang";
import CommonDialog from "@/components/motion-comics/dialog/CommonDialog.vue";
import LanguageItem from "@/components/motion-comics/LanguageItem.vue";
import { useComicStore } from "@/stores/comic";
import _ from "lodash";

const comicStore = useComicStore();

const props = defineProps({
  visible: {
    type: Boolean,
    required: true,
  },
});
const emit = defineEmits(["update:visible", "update:language"]);

const languages = _.keys(Language);
const isLanguageSelected = (lang) => comicStore.language == lang;
const updateLanguage = (lang) => {
  comicStore.language = Language[lang];
  emit("update:language", Language[lang]);
  emit("update:visible", false);
};
</script>

<template>
  <CommonDialog
    title="Select Language"
    message="Select the language of your story"
    :visible="props.visible"
    @update:visible="emit('update:visible', $event)"
  >
    <template #content>
      <div class="spacer-2" />
      <div class="lang">
        <div v-for="item of languages">
          <LanguageItem
            @click="updateLanguage(item)"
            :selected="isLanguageSelected(item)"
          >
            <template #text>
              <p>{{ item }}</p></template
            >
          </LanguageItem>
        </div>
      </div>
    </template>
  </CommonDialog>
</template>

<style scoped>
.lang {
  display: grid;
  gap: 1.2rem;
  grid-template-columns: repeat(2, 0.2fr);
}
.spacer-2 {
  margin-top: 1.5rem;
}
</style>
