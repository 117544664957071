<script setup>
import NumberPicker from "@/components/motion-comics/NumberPicker.vue";
import * as _ from "lodash";
import Meridiem from "@/data/model/timeType";
import { computed, ref } from "vue";
import moment from "moment";

const props = defineProps({
  value: {
    type: Date,
    required: true,
  },
});

const emit = defineEmits(["input"]);

const todayDate = ref(moment());
const momentDate = computed(() => moment(props.value));

const hours = computed(() => Number(momentDate.value.format("hh")));
const mins = computed(() => Number(momentDate.value.format("mm")));
const isPM = computed(() => momentDate.value.format("A") == "PM");

const isGreater = computed(() => momentDate.value.isAfter(todayDate.value));
const minHour = computed(() => isGreater.value ? 1 : Number(todayDate.value.format("hh")));
const minMinutes = computed(() => isGreater.value ? 0 : Number(todayDate.value.format("mm")));

const meridiem = _.keys(Meridiem);

const currentMeridiem = computed(() =>
  isPM.value ? Meridiem.PM : Meridiem.AM
);

const updateDate = (type /*"meridiem" | "hours" | "minutes" */, value) => {
  const date = new Date(props.value);
  switch (type) {
    case "meridiem":
      if (value == Meridiem.PM) {
        date.setHours(date.getHours() + 12);
      } else {
        date.setHours(date.getHours() - 12);
      }
      break;
    case "hours":
      if (isPM.value && value != 12) {
        date.setHours(value + 12);
      } else {
        date.setHours(value);
      }
      break;
    case "minutes":
      date.setMinutes(value);
      break;
  }
  emit("input", date);
};
</script>

<template>
  <div>
    <div class="full-width flex-horizontal flex-gap-1">
      <v-chip-group
        :value="currentMeridiem"
        @change="updateDate('meridiem', $event)"
        class="chip-group"
        mandatory
      >
        <v-chip
          :key="item"
          :value="item"
          v-for="item in meridiem"
          class="chip-style unselected"
          active-class="selected"
          >{{ item }}</v-chip
        >
      </v-chip-group>
    </div>
    <div class="ma-2"></div>
    <div class="full-width flex-horizontal flex-row-center-horizontal">
      <NumberPicker
        :max="12"
        :min="minHour"
        :value="hours"
        @update:value="updateDate('hours', $event)"
      />
      <div class="divider-vertical"></div>
      <NumberPicker
        :value="mins"
        @update:value="updateDate('minutes', $event)"
        :min="minMinutes"
        :max="59"
      />
    </div>
  </div>
</template>

<style scoped>
.chip-style {
  border-radius: 7px;
}
.chip-group {
  display: flex;
  gap: 0.2rem;
}
.unselected {
  font-weight: bold;
  background-color: var(--color-surface);
}
.selected {
  background-color: var(--color-text);
  color: var(--color-background);
}
.btn-common {
  background-color: var(--color-surface);
}
.divider-vertical {
  width: 1px;
  height: 100;
  background-color: var(--color-border);
}
</style>
