<script setup>
import moment from "moment";
import * as _ from "lodash";
import { computed } from "vue";
import { useVideoStore } from "@/stores/videoUi";
import { useMainAlertStore } from "@/stores/alert";
import createViewModel from "@/pages/motion-comics/CreateViewModel";

const props = defineProps({
  data: {
    type: Object, // Episode
    required: true,
  },
});

const uiStore = useVideoStore();
const mainAlertStore = useMainAlertStore();

const states = computed(() => {
  const chipData = [];
  chipData.push({
    text: _.capitalize(props.data.state),
    color: props.data.state == "PUBLISHED" ? "error" : "info",
  });

  const release = props.data.release;
  if (release && release.state == "LOCKED") {
    chipData.push({
      text: "Locked",
      color: "yellow",
    });
  }
  return chipData;
});

const updatedAt = computed(() => moment(props.data.updatedAt).fromNow());

const isReleased = computed(() => props.data.release.state == "DONE");
const isScheduled = computed(() =>
  ["PENDING", "LOCKED"].includes(props.data.release.state)
);

const isScheduleButtonVisible = computed(() => _.isNil(props.data.release.releaseAt) || props.data.release.state == "CANCELLED");
// const isScheduleCancelButtonVisible = computed(() => {
//   return (
//     !isScheduleButtonVisible &&
//     moment(props.data.release.releaseAt).isAfter(moment())
//   );
// });

const dataFormatted = computed(() =>
  moment(props.data.release.releaseAt).format("DD MMM YYYY, hh:mm A")
);

const emit = defineEmits(["click:upload", "click:schedule"]);

const cancelScheduledRelease = async (event) => {
  event.stopPropagation();
  const part = props.data;
  mainAlertStore.showAlert({
    title: "Cancel Scheduled Release",
    type: "warning",
    message: `Are you sure you want to cancel scheduled release of episode "${part.partNumber} • ${part.title}"?`,
    negativeButtonText: "Cancel",
    positiveButtonText: "Yes",
    positiveClick: async () => {
      uiStore.enableLoadingFullScreen();
      createViewModel.cancelRelease(part)
        .catch((e) => {
          mainAlertStore.showAlert({
            title: "Error",
            type: "error",
            message: `Failed to cancel scheduled release: ${e}`,
            positiveButtonText: "OK",
          });
        })
        .finally(() => {
          uiStore.stopLoading();
        });
    },
  });
}

</script>

<template>
  <div class="container" @click="$emit('click:upload', data)" v-ripple="true">
    <v-card>
      <v-img
        eager
        width="60"
        height="60"
        cover
        class="image"
        :aspect-ratio="1"
        :src="data.coverImage"
      ></v-img>
    </v-card>

    <h4 class="ep-number">{{ data.partNumber }}</h4>
    <div class="ep-name">
      <h4>{{ data.title }}</h4>
      <div class="spacer-0-5"></div>
      <p class="text-bold updated-at-field">Updated: {{ updatedAt }}</p>
    </div>

    <v-chip-group>
      <v-chip
        small
        v-for="state in states"
        :key="state.text"
        outlined
        :color="state.color"
        class="state-chip text-bold"
        >{{ state.text }}</v-chip
      >
    </v-chip-group>

    <!-- TODO(KP): This button feature is on hold -->
<!--    <v-btn-->
<!--      @click="$emit('click:upload', data)"-->
<!--      class="text-bold radius-rounded"-->
<!--      color="secondary"-->
<!--      :outlined="props.data.isUploaded"-->
<!--      >{{ data.isUploaded ? "Uploaded" : "Upload" }}</v-btn-->
<!--    >-->

    <h6 v-if="isScheduled">Scheduled at {{ dataFormatted }}</h6>
    <h6 v-if="isReleased">Released on {{ dataFormatted }}</h6>

    <v-btn
      v-if="isScheduleButtonVisible"
      @click="$emit('click:schedule', data); $event.stopPropagation()"
      class="text-bold radius-rounded"
      color="info"
      outlined
      >Schedule
      <div class="spacer-h-0-5"></div>
      <v-icon small>mdi-calendar-range-outline</v-icon>
      <v-icon small>mdi-chevron-down</v-icon>
    </v-btn>
    <v-btn v-if="isScheduled" @click="cancelScheduledRelease" small class="radius-rounded" color="info" text
      >Cancel</v-btn
    >
  </div>
</template>

<style scoped>
.container {
  display: flex;
  align-items: center;
  justify-content: space-around;
  gap: 0.8rem;
}
.ep-name {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  flex-grow: 1;
}
.ep-number {
  flex-grow: 0.1;
  margin-inline-start: 3%;
}
.image {
  background-color: var(--color-surface);
}
.state-chip {
  opacity: 0.8;
  pointer-events: none;
}
.updated-at-field {
  opacity: 0.5;
  font-size: small;
}
</style>
