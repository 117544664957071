import { useComicStore } from "@/stores/comic";
import { useDataStore } from "@/stores/data";
import { apiSeriesV2, apiComic, apiTransliterate, apiPratilipiV2, apiPratilipiV1, apiContentV1 } from "@/http/DataAccessor.js";
import Language from "@/data/model/lang";
import * as _ from "lodash";
import moment from "moment";

class CreateViewModel {
  _comicStore = useComicStore();
  _dataStore = useDataStore();

  _id = null;

  async init(id) {
    this._id = id;
    return Promise.all(
      [this.fetchSeries(), this.fetchSeriesParts()]
    )
  }

  reload() {
    const state = this._dataStore.getRelativeEpisodeStates
    return Promise.all(
      [this.fetchSeries(), this.fetchSeriesParts(state)]
    )
  }

  fetchSeries() {
    return apiComic
      .getMetaSeriesById(this._id)
      .then((series) => {
        this.updateMetadata(series);
        this.updateEpisodeMetadata(series);

        this._comicStore.setSeriesData(series);

        return this.fetchAvailableGenres(this._comicStore.seriesData.language);
      });
  }

  fetchSeriesParts(state = 'PUBLISHED,DRAFTED') {
    return apiComic.getMetaPratilipis(this._id, state, 500)
      .then((pratilipiList) => {
        const pratilipiIds = pratilipiList.map(part => part.pratilipiId);
        if (!_.isEmpty(pratilipiIds)) {
          return apiComic.getRelease(pratilipiIds)
            .then((result) => result.data)
            .then((releases) => {
              const seriesData = this._comicStore.seriesData;

              this._setPratilipisReleaseData(releases, pratilipiList);
              this._setPratilipisPartNumber(state, seriesData, pratilipiList);

              this._comicStore.setSeriesParts(pratilipiList);
            })
        } else {
          return this._comicStore.setSeriesParts(pratilipiList);
        }
      })
  }

  _setPratilipisReleaseData(releases, pratilipiList) {
    pratilipiList.forEach((part) => {
      const release = _.find(releases, (it) => it.pratilipiId == part.pratilipiId);
      if (release) {
        part.release = release;
      } else {
        part.release = {};
      }
    });
  }

  _setPratilipisPartNumber(state, seriesData, pratilipiList) {
    const partsInSeries = _.flatMap(state.toLowerCase().split(","), (state) =>
      _.get(seriesData, ["seriesPart", state], [])
    );
    const partsInSeriesById = _.keyBy(partsInSeries, "pratilipiId");

    pratilipiList.forEach((part) => {
      const { pratilipiId } = part;
      part.partNumber = _.get(partsInSeriesById, [pratilipiId, "part"]);
    });
  }


  fetchAvailableGenres(language) {
    return apiComic
      .getGenres(language)
      .then((result) => result.data)
      .then((data) => data.map((it) => it.attributes))
      .then((genres) => this._dataStore.updateGenres(genres));
  }

  deleteComic() {
    return apiSeriesV2.deleteSeries(this._id);
  }

  getEpisode(pratilipiId) {
    const seriesParts = this._comicStore.seriesParts;
    return _.find(seriesParts, (part) => part.pratilipiId == pratilipiId);
  }

  addEpisode() {
    const seriesData = this._comicStore.seriesData;
    return apiComic.createPratilipi({
      "title": "A new chapter",
      "type": "STORY",
      "state": "DRAFTED",
      "language": seriesData.language,
      "contentType": seriesData.contentType,
      "authorId": parseInt(seriesData.authorId),
    }).then((data) => {
        return apiSeriesV2.addPratilipi(
          this._id, data.pratilipiId
        ).then(() => data);
    }).catch((err)=>{
      console.error("error while creating pratilipi", err)
    })
  }

  updateEpisode(pratilipiId, body) {
    const seriesData = this._comicStore.seriesData;
    return apiComic.patchPratilipi(pratilipiId, {
      ...body,
      "contentType": seriesData.contentType,
      "language": seriesData.language,
      "authorId": parseInt(seriesData.authorId),
    }).then(() => this.reload());
  }

  removeEpisode(pratilipiId) {
    const seriesData = this._comicStore.seriesData;
    const seriesParts = this._comicStore.seriesParts;
    return apiComic.removePratilipi(pratilipiId)
      .then(() => {
        const newSeriesParts = seriesParts.filter((part) => part.pratilipiId != pratilipiId);
        this._comicStore.setSeriesParts(newSeriesParts);

        const drafted = seriesData.seriesPart.drafted.filter((part) => part.pratilipiId != pratilipiId);
        const published = seriesData.seriesPart.published.filter((part) => part.pratilipiId != pratilipiId);
        seriesData.seriesPart.drafted = drafted;
        seriesData.seriesPart.published = published;
        this._comicStore.setSeriesData(seriesData);
      });
  }

  removeEpisodes(pratilipiIds) {
    const seriesData = this._comicStore.seriesData;
    const seriesParts = this._comicStore.seriesParts;
    const promises = pratilipiIds.map((id) => apiComic.removePratilipi(id));
    return Promise.all(promises)
      .then(() => {
        const newSeriesParts = seriesParts.filter((part) => !pratilipiIds.includes(part.pratilipiId));
        this._comicStore.setSeriesParts(newSeriesParts);

        const drafted = seriesData.seriesPart.drafted.filter((part) => !pratilipiIds.includes(part.pratilipiId));
        const published = seriesData.seriesPart.published.filter((part) => !pratilipiIds.includes(part.pratilipiId));
        seriesData.seriesPart.drafted = drafted;
        seriesData.seriesPart.published = published;
        this._comicStore.setSeriesData(seriesData);
      })
  }

  getEpisodeContent(pratilipiId) {
    return apiContentV1.getMetaContent(pratilipiId)
  }

  updateMetadata(seriesData) {
    this._comicStore.title = seriesData.title;
    this._comicStore.titleEn = seriesData.titleEn;
    this._comicStore.summary = seriesData.summary;
    this._comicStore.state = seriesData.state;
    this._comicStore.completedSeries = seriesData.state == 'COMPLETED';
    this._comicStore.language = Language[_.capitalize(seriesData.language)];

    const genres = seriesData.category.system.map((genre) => genre.name);
    if (_.isArray(genres)) {
      this._comicStore.updateGenres(genres);
    }
  }

  updateEpisodeMetadata(seriesData) {}

  publishSeriesMetadata() {
    const seriesData = this._comicStore.seriesData;
    const system = this._comicStore.genres.map((genre) => {
      return _.find(this._dataStore.genreMetadata, (it) => it.name == genre).referenceId;
    })
    const data = {
      title: this._comicStore.title,
      titleEn: this._comicStore.titleEn,
      summary: this._comicStore.summary,
      language: this._comicStore.language.toUpperCase(),
      type: "STORY",
      authorId: seriesData.authorId,
      contentType: seriesData.contentType,
      state: this._comicStore.state,
      category: { system, suggested: [] }
    };
    return apiSeriesV2.updateSeriesById(this._id, data)
      .then((data) => this._comicStore.state = data.state);
  }

  async scheduleRelease(part, date) {
    const seriesParts = this._comicStore.seriesParts;
    return apiComic
      .setRelease(
        part.pratilipiId,
        moment(date)
          .minutes(0)
          .toISOString()
      )
      .then((data) => {
        part.release = data;
        const newSeriesParts = seriesParts.map((it) => {
          if (it.pratilipiId == part.pratilipiId) {
            return part;
          }
          return it;
        });
        this._comicStore.setSeriesParts(newSeriesParts);
        return data
      })
      .catch((error) => {
        if (
          Array.isArray(error.response.data) &&
          error.response.data.length > 0 &&
          error.response.data[0].message
        ) {
          alert(error.response.data[0].message);
        }

        return Promise.reject(error);
      });
  }

  async cancelRelease(part) {
    const seriesParts = this._comicStore.seriesParts;
    const release = part.release;

    if (release.state != "PENDING") {
      throw("Can cancel pending releases only");
    }

    return apiComic.cancelRelease(release)
      .then((data) => {
        part.release = data;
        const newSeriesParts = seriesParts.map((it) => {
          if (it.pratilipiId == part.pratilipiId) {
            return part;
          }
          return it;
        });
        this._comicStore.setSeriesParts(newSeriesParts);
        return data;
      });
  }

  async publishSeries() {
    return Promise.resolve(this._comicStore.publishedParts)
      .then((episodes) => _.isEmpty(episodes) ? Promise.reject({
        title: "Publish Error",
        message: "Cannot publish series with drafted episodes, at least one episode must be published."
      }) : Promise.resolve())
      .then(() => {
        this._comicStore.state = "INPROGRESS";
        return this.publishSeriesMetadata();
      })
      .then(() => this.fetchSeries())
  }

  async unPublishSeries() {
    this._comicStore.state = "DRAFTED"
    return this.publishSeriesMetadata()
      .then(() => this.fetchSeries())
  }

  async markSeriesAsCompleted() {
    this._comicStore.state = "PUBLISHED"
    return this.publishSeriesMetadata()
      .then(() => this.fetchSeries())
  }

  updateSeriesCoverImage(file) {
    const seriesData = this._comicStore.seriesData;
    return this._verifyImage(file).then(() => apiPratilipiV1.saveSeriesCoverImage(this._id, file))
      .then((data) => {
        seriesData.coverImage = data.coverImageUrl;
        this._comicStore.setSeriesData(seriesData);
      });
  }

  updatePratilipiCoverImage(pratilipiId, file) {
    const seriesParts = this._comicStore.seriesParts;
    return this._verifyImage(file).then(() => apiPratilipiV1.saveCoverImage(pratilipiId, file))
      .then((data) => {
        const newSeriesParts = seriesParts.map(item => {
          if (item.pratilipiId == pratilipiId) {
            item.coverImage = data.coverImageUrl;
          }
          return item;
        });
        this._comicStore.setSeriesParts(newSeriesParts);
        return data;
      });
  }

  uploadPratilipiVideo(pratilipiId, file) {
    const seriesData = this._comicStore.seriesData;
    const seriesParts = this._comicStore.seriesParts;
    return this._verifyVideo(file).then((data) => {
        return apiContentV1.uploadVideo(
          seriesData.seriesId,
          pratilipiId,
          file,
          data.duration,
          data.orientation
        ).then((data) => _.isEmpty(data) ? Promise.reject({
          title: "Upload Error",
          message: "Video upload failed due to unknown error.",
          type: "warning",
        }) : Promise.resolve(data))
      }
    )
  }

  _verifyImage(file) {
    const img = new Image();
    return new Promise((resolve, reject) => {
      img.src = window.URL.createObjectURL(file);
      img.onload = () => {
        if (img.width > 500 || img.height > 500 || file.size > 1572864) {
          reject({
            message: "Image size should not exceed 1.5MB and maximum resolution should be 500 pixels(W) X 500 pixels(H)",
            title: "Upload Error!",
            type: "warning",
          });
        } else {
          resolve();
        }
      };
    });
  }

  _verifyVideo(file) {
    return new Promise((resolve, reject) => {
      const video = document.createElement("video");
      video.preload = 'metadata';
      video.onerror = function() {
        reject({
          message: "Video is not supported",
          title: "Upload Error!",
          type: "error",
        });
      }
      video.onloadedmetadata = function() {
        window.URL.revokeObjectURL(video.src);
        const duration = video.duration * 1000;
        resolve({
          duration: duration,
          orientation: video.videoHeight > video.videoWidth ? 'portrait' : 'landScape', /* S should be small but... */
        });
      }
      video.src = window.URL.createObjectURL(file);
    });
  }

  transliterateToEnglish(text) {
    const language = this._comicStore.language;
    if (language.toLowerCase() == "english") {
      return Promise.resolve(text);
    }
    return apiTransliterate.transliterate(this._comicStore.language, "english", text)
  }

  autoGenerateEnglishTitle() {
    return this.transliterateToEnglish(this._comicStore.title)
      .then((data) => {
        this._comicStore.titleEn = data;
      });
  }
}

export default new CreateViewModel();
