<script setup>
import ImageUploader from "@/components/motion-comics/ImageUploader.vue";
import InputItem from "@/components/motion-comics/InputItem.vue";
import Menu from "@/components/motion-comics/Menu.vue";
import AlertDialog from "@/components/motion-comics/dialog/AlertDialog.vue";
import UploadIcon from "@/components/icons/upload.vue";
import { useVideoStore } from "@/stores/videoUi";
import { useUploadMenuStore } from "@/stores/menu";
import { useUploadAlertStore } from "@/stores/alert";
import { ref, computed, watch, onMounted } from "vue";
import createViewModel from "@/pages/motion-comics/CreateViewModel";
import * as _ from "lodash";
const props = defineProps({
  store: {
    type: Object,
    required: true,
  },
});
const data = computed(() => props.store.uploadDialogData);

const title = ref("");
const titleEn = ref("");
const summary = ref("");
const _imageUrl = ref("");
const imageUrl = computed(() => {
  const url = _imageUrl.value;
  return url.includes("?") ? url : "";
});

const content = ref({});

const isUploaded = computed(() => content.value && !_.isEmpty(content.value.streamingUrl));
const statusMessage = computed(() => {
  if (isUploaded.value) {
    return "Drag and drop to replace existing upload";
  }
  return "Drag and drop video files to upload";
});

const isSaveEnabled = computed(() => {
  const lTitleEn = titleEn.value ? titleEn.value : "";
  const lSummary = summary.value ? summary.value : "";

  const pratilipi = props.store.uploadDialogData.pratilipi
  const pTitle = pratilipi.title
  const pTitleEn = (pratilipi.titleEn || "")
  const pSummary = (pratilipi.summary || "")

  return title.value != pTitle || lTitleEn != pTitleEn || lSummary != pSummary
})

const emit = defineEmits(["update:visible", "update:delete"]);
const uiStore = useVideoStore();
const uploadAlertStore = useUploadAlertStore();
const uploadMenuStore = useUploadMenuStore();

const setMenuItems = () => {
  const menuItems = [
    {
      id: "delete",
      color: "error",
      icon: "mdi-delete-outline",
      text: "Delete",
      enable: () => true,
    },
  ];
  uploadMenuStore.setMenuItems(menuItems)
};

const onMenuItemClick = async (id) => {
  if (id == "delete") {
    emit("update:delete", props.store.uploadDialogData.pratilipi);
  }
  props.store.visible = false;
};

const onCancel = () => {
  props.store.visible = false;
};

const onSave = async () => {
  uiStore.enableUploadDialogLoading();
  const pratilipi = props.store.uploadDialogData.pratilipi
  await createViewModel.updateEpisode(pratilipi.pratilipiId, {
    title: title.value,
    titleEn: titleEn.value,
    summary: summary.value,
    type: pratilipi.type,
    state: pratilipi.state,
  }).catch((e) => {
    uploadAlertStore.showAlert({
      title: "Error",
      type: "error",
      message: `Failed to update episode: ${e}`,
      positiveButtonText: "OK",
    });
  });
  uiStore.stopUploadDialogLoading();
  props.store.visible = false;
};

// temporary store, might need state to handle uploads
const uploadState = ref(false);

const onUploadCoverImage = async (file) => {
  uiStore.enableUploadDialogLoading();
  const pratilipi = props.store.uploadDialogData.pratilipi
  await createViewModel.updatePratilipiCoverImage(pratilipi.pratilipiId, file)
      .then(data => { _imageUrl.value = data.coverImageUrl })
      .catch((e) => {
        uploadAlertStore.showAlert({
          title: e.title || "Error",
          type: "warning",
          message: e.message || `Failed to upload pratilipi cover image (${e})`,
          negativeButtonVisible: false,
          positiveButtonText: "OK",
        });
      });
  uiStore.stopUploadDialogLoading();
}

const pickVideo = (callback) => {
  const input = document.createElement("input");
  input.type = "file";
  input.accept = "video/*";
  input.onchange = (e) => {
    const file = e.target.files[0];
    if (file) {
      callback(file);
    }
  };
  input.click();
}

const onUploadVideo = async () => {
  pickVideo(async (file) => {
    uiStore.enableUploadDialogLoading();
    const pratilipi = props.store.uploadDialogData.pratilipi
    await createViewModel.uploadPratilipiVideo(pratilipi.pratilipiId, file)
        .then(() => init())
        .then(() => {
          uploadAlertStore.showAlert({
            title: "Success",
            type: "info",
            message: "Video uploaded successfully",
            negativeButtonVisible: false,
            positiveButtonText: "OK",
          });
        })
        .catch((e) => {
          uploadAlertStore.showAlert({
            title: e.title || "Error",
            type: "warning",
            message: e.message || `Failed to upload pratilipi video (${e})`,
            negativeButtonVisible: false,
            positiveButtonText: "OK",
          });
        });
    uiStore.stopUploadDialogLoading();
  });
}

const autogenerateEnglishTitle = async () => {
  uiStore.enableUploadDialogLoading();
  await createViewModel.transliterateToEnglish(title.value)
      .then((text) => { titleEn.value = text }).catch(() => {
        uploadAlertStore.showAlert({
          title: "Error",
          type: "warning",
          message: "Failed to auto generate English title.",
          negativeButtonVisible: false,
          positiveButtonText: "OK",
        });
      })
  uiStore.stopUploadDialogLoading();
}

const init = async () => {
  uiStore.enableUploadDialogLoading();
  await createViewModel.getEpisodeContent(props.store.uploadDialogData.pratilipi.pratilipiId)
      .then((data) => {
        content.value = data.content;
      })
      .catch((e) => {
        uploadAlertStore.showAlert({
          title: "Error",
          type: "warning",
          message: `Failed to get episode content. ${e}`,
          negativeButtonVisible: false,
          positiveButtonText: "OK",
          positiveClick: onCancel
        });
      })
  uiStore.stopUploadDialogLoading();
}

watch(
    () => props.store.visible,
    async (visible) => {
      title.value = props.store.uploadDialogData.pratilipi.title;
      titleEn.value = props.store.uploadDialogData.pratilipi.titleEn;
      summary.value = props.store.uploadDialogData.pratilipi.summary;
      _imageUrl.value = props.store.uploadDialogData.pratilipi.coverImage;

      if (visible) {
        await init();
      }
    });

onMounted(() => {
  setMenuItems();
});
</script>

<template>
  <v-dialog persistent width="40rem" v-model="props.store.visible">
    <v-card rounded="lg" class="card">
      <div>
        <div :class="uiStore.isUploadDialogLoading ? 'dim' : ''">
          <div
            class="flex-horizontal flex-row-center-vertical flex-row-spaced-between"
          >
            <div class="flex-horizontal flex-row-center-vertical flex-gap-0-5">
              <h5>{{ data.pratilipi.partNumber }}</h5>
              <h4>•</h4>
              <h3>{{ title }}</h3>
            </div>
            <div class="flex-horizontal flex-row-center-vertical flex-gap-0-5">
              <v-btn
                @click="onCancel"
                small
                class="font-weight-bold radius-rounded"
                text
                color="info"
                >Cancel</v-btn
              >
              <v-btn
                @click="onSave"
                :disabled="!isSaveEnabled"
                small
                class="text-dark font-weight-bold radius-rounded"
                color="info"
                >Save</v-btn
              >
              <Menu :store="uploadMenuStore" menu-icon-size="20px" @click:item="onMenuItemClick" />
            </div>
          </div>
          <div class="ma-5" />
          <div class="detail-container">
            <ImageUploader
              :image-url="imageUrl"
              card-background-color="var(--vt-c-black-light)"
              container-width="150px"
              icon-size="32px"
              circle-diameter="3.5rem"
              @update:file="onUploadCoverImage"
            />
            <div class="full-width">
              <!-- Title -->
              <InputItem
                background-color="var(--vt-c-black-light)"
                header="Episode Title"
              >
                <template #input>
                  <input v-model="title" type="text" />
                </template>
              </InputItem>
              <div class="ma-3" />
              <!-- Title (en) -->
              <InputItem background-color="var(--vt-c-black-light)" header="Comic Title (English)">
                <template #input>
                  <div class="input-common-container">
                    <input v-model="titleEn" type="text" />
                    <v-btn
                        @click="autogenerateEnglishTitle"
                        icon
                        small
                        rounded
                        class="edit-btn btn"
                    >
                      <v-icon rounded x-small>mdi-auto-fix</v-icon></v-btn
                    >
                  </div>
                </template>
              </InputItem>
              <div class="ma-3" />
              <!-- Summary -->
              <InputItem
                background-color="var(--vt-c-black-light)"
                header="Summary"
              >
                <template #input>
                  <textarea
                    v-model="summary"
                    variant="flat"
                    no-resize="true"
                    class="summary-input"
                  />
                </template>
              </InputItem>
            </div>
          </div>
          <div class="ma-5" />
          <div class="upload-container">
            <div class="ma-3" />
            <v-btn class="text-bold" color="secondary" @click="onUploadVideo">
              <div
                class="flex-horizontal flex-row-center-vertical flex-gap-0-5"
              >
                <div v-if="!isUploaded" class="flex-horizontal flex-gap-0-5"><UploadIcon class="icon" /> Browse Files</div>
                <div v-if="isUploaded" class="flex-horizontal flex-gap-0-5">Uploaded <v-icon small>mdi-pencil</v-icon></div>
              </div>
            </v-btn>

            <div class="ma-2" />
            <p class="text-drag">{{ statusMessage }}</p>
            <div class="ma-2" />
          </div>
        </div>
        <div class="progress-bar" v-show="uiStore.isUploadDialogLoading">
          <v-progress-linear
            :active="true"
            indeterminate
            height="3"
            color="secondary"
          ></v-progress-linear>
          <div class="upload-overlay"></div>
        </div>

        <AlertDialog :store="uploadAlertStore" />

      </div>
    </v-card>
  </v-dialog>
</template>

<style scoped>
.dim {
  opacity: 0.4;
}
.input-common-container {
  display: flex;
  justify-content: space-between;
}
.text-drag {
  font-size: 0.9rem;
  font-weight: bold;
  color: var(--color-accent);
}
.icon {
  width: 20px;
}
.upload-container {
  border: 0.5px solid var(--color-border-secondary);
  border-radius: 0.5rem;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.card {
  color: var(--color-text);
  background-color: var(--color-divider);
  padding: 2rem;
}
.summary-input {
  font-size: small;
  height: 10ch;
  width: 100%;
  overflow: overlay;
  resize: none;
}
.detail-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
}
.progress-bar {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
}
.upload-overlay {
  width: 100%;
  height: 99%;
}
@media (min-width: 720px) {
  .detail-container {
    flex-direction: row;
    align-items: flex-start;
  }
}
</style>
